import NavbarAdm from "../../components/Nav/Navbar"
import { ToolBar } from "../../components/ToolBar/ToolBar"
import "./myPropertiesList.css";
import { IoCreateOutline, IoLocationOutline, IoEyeOutline, IoHeartOutline, IoLogoWhatsapp, IoCallOutline, IoHomeOutline, IoInfiniteOutline, IoTrashOutline} from 'react-icons/io5';
import { DateFormat2 } from "../../components/DateFormat2/DateFormat2";
import { EditStatusProperty } from "../../components/EditStatusProperty/EditStatusProperty";
import { CountersViews } from "../../components/CountersProperties/CountersViews";
import { CountersFavorites } from "../../components/CountersProperties/CountersFavorites";
import { CountersContact } from "../../components/CountersProperties/CountersContact";
import { CountersWhatsapp } from "../../components/CountersProperties/CountersWhatsapp";
import { useContext, useEffect } from "react";
import { useState } from "react";
import api from "../../services/api";
import { DeleteProperty } from "../../components/DeleteProperty/DeleteProperty";
import { ViewPropertyList } from "../../components/ViewPropertyList/ViewPropertyList";
import { MatchProperty } from "../../components/MatchProperty/MatchProperty";
import { MatchPropertySearch } from "../../components/MatchPropertySearch/MatchPropertySearch";
import { AuthContext } from "../../contexts/Auth";

export function MyPropertiesList() {
    const Local = localStorage.getItem("adm-suachave");
    const user = JSON.parse(Local);

    const {newProperty} = useContext(AuthContext);

    const [type, setType] = useState("");
    const [subType, setSubType] = useState("");
    const [availability, setAvailability] = useState("");
    const [status, setStatus] = useState("");
    const [emphasis, setEmphasis] = useState(false);
    const [plains, setPlains] = useState("");

    const [filter, setFilter] = useState("");

    const [search, setSearch] = useState("");
    const searchLower = search.toLowerCase();
    const [data, setData] = useState([]);
    const [myIntegrationProperty, setMyIntegrationProperty] = useState([]);
    
    useEffect(() => {
        async function loadPaymet() {
            await api.get(`/myplain/${user.id}`).then((res) => {
                setPlains(res.data[0]);
            }).catch((err) => {
                console.error(err);
            });
        }

        loadPaymet()
    },[])

    useEffect(() => {
        async function loadProperty() {
            await api.get(`/property/company/${user.id}`).then((res) => {
                setData(res.data);
            }).catch((err) => {
                console.error(err);
            });
        }

        loadProperty()
    },[])

  useEffect(() => {
      async function loadIntegrationsInfo() {
          await api.get(`/integrationsCompany/company/${user.id}`, data).then((res) => {
            loadIntegrations(
                res.data[0].idCompany, res.data[0].nameCompany, res.data[0].avatarCompany, res.data[0].business, res.data[0].link, res.data[0].type, res.data[0].email, res.data[0].phone, res.data[0].whatsapp, 
            )
          }).catch((err) => {
              console.error(err)
          });
      }

      async function loadIntegrations(idCompany, nameCompany, avatarCompany, business, link, type, email, phone, whatsapp ) {
        const data = {idCompany, nameCompany, avatarCompany, business, link, type, email, phone, whatsapp }
          await api.post(`/integrations`,data ).then((res) => {
            setMyIntegrationProperty(res.data);
          }).catch((err) => {
              console.error(err)
          });
      }

      loadIntegrationsInfo()
  },[])

    if(!data) {
        return (
            <h5>Carregando...</h5>
        )
    }

    function handleEmphasis(e) {
        
        if(e.target.value === "true") {
            setEmphasis(true)
            setStatus("")
            setSubType("")
            setType("")
            setAvailability("")
            setSearch("")
        } else {
            setEmphasis(false)
            setStatus("")
            setSubType("")
            setType("")
            setAvailability("")
            setSearch("")
        }
        console.log(e.target.value)
    }
    function handleType(e) {
        setType(e.target.value)
        console.log(e.target.value)
    }
    function handleSubType(e) {
        setSubType(e.target.value)
        console.log(e.target.value)
    }
    function handleStatus(e) {
        setStatus(e.target.value)
        console.log(e.target.value)
    }
    function handleAvailability(e) {
        setAvailability(e.target.value)
        console.log(e.target.value)
    }
    function handleClear() {
        setStatus("")
        setSubType("")
        setType("")
        setAvailability("")
        setEmphasis(false)
    }
    function selectLists(data) {
        setFilter(data);
        console.log(data)
    }
    

    const filterIdIntegration = data?.filter(filter => filter.idIntegration === undefined || filter.idIntegration === null || filter.idIntegration === "" )
    const filterIdIntegration2 = filterIdIntegration?.filter(filter => filter.id !== data?.id )
    const OlsIntegrations = data?.filter((propertiesNew) => propertiesNew.idIntegration !== myIntegrationProperty?.idIntegration);
    const newIntegrations = OlsIntegrations?.filter((propertiesNew) => propertiesNew.idIntegration === myIntegrationProperty?.idIntegration);


    console.log(filterIdIntegration);
    console.log(filterIdIntegration2);
    console.log(newIntegrations);
    console.log(OlsIntegrations);

    const propertiesSelected = filter === "New" ? myIntegrationProperty : filter === "Old" ? OlsIntegrations : data
   
    const emphasisFilter = propertiesSelected?.filter((companies) => companies.emphasis === emphasis)
    const statusFilter = propertiesSelected?.filter((companies) => companies.status === status || companies.status === "Aluguel e Venda")
    const availabilityFilter = propertiesSelected?.filter((companies) => companies.availability === availability)
    const availabilityStatusFilter = propertiesSelected?.filter((companies) => companies.availability === availability && companies.status === status || companies.status === "Aluguel e Venda")
    const availabilityStatusFilterType = propertiesSelected?.filter((companies) => companies.availability === availability && companies.status === status || companies.status === "Aluguel e Venda" && companies.type === type)
    const availabilityStatusFilterTypeSubtype = propertiesSelected?.filter((companies) => companies.availability === availability && companies.status === status || companies.status === "Aluguel e Venda" && companies.type === type && companies.subType === subType)
    const statusFilterTypeSubtype = propertiesSelected?.filter((companies) =>  companies.status === status || companies.status === "Aluguel e Venda" && companies.type === type && companies.subType === subType)
    const availabilityFilterTypeSubtype = propertiesSelected?.filter((companies) => companies.availability === availability && companies.type === type && companies.subType === subType)
    const statusFilterType = propertiesSelected?.filter((companies) =>  companies.status === status || companies.status === "Aluguel e Venda" && companies.type === type )
    const availabilityFilterType = propertiesSelected?.filter((companies) => companies.availability === availability && companies.type === type )
    const typeFilter = propertiesSelected?.filter((companies) => companies.type === type)
    const subTypeFilter = propertiesSelected?.filter((companies) => companies.type === type && companies.subType === subType)
    const searchFilter = propertiesSelected?.filter((companies) => companies.title.toLowerCase().includes(searchLower)
                                                                    || companies.idIntegration.toLowerCase().includes(searchLower)
                                                                    || companies.id.toLowerCase().includes(searchLower))
  
    const filterData = search !== "" && status === "" && availability === "" && type === "" && subType === "" && emphasis === false ? searchFilter
                     : search === "" && status !== "" && availability === "" && type === "" && subType === "" && emphasis === false ? statusFilter 
                     : search === "" && status === "" && availability !== "" && type === "" && subType === "" && emphasis === false ? availabilityFilter 
                     : search === "" && status !== "" && availability !== "" && type === "" && subType === "" && emphasis === false ? availabilityStatusFilter 
                     : search === "" && status !== "" && availability !== "" && type !== "" && subType === "" && emphasis === false ? availabilityStatusFilterType 
                     : search === "" && status !== "" && availability !== "" && type !== "" && subType !== "" && emphasis === false ? availabilityStatusFilterTypeSubtype 
                     : search === "" && status !== "" && availability === "" && type !== "" && subType !== "" && emphasis === false ? statusFilterTypeSubtype 
                     : search === "" && status === "" && availability !== "" && type !== "" && subType !== "" && emphasis === false ? availabilityFilterTypeSubtype 
                     : search === "" && status !== "" && availability === "" && type !== "" && subType === "" && emphasis === false ? statusFilterType 
                     : search === "" && status === "" && availability !== "" && type !== "" && subType === "" && emphasis === false ? availabilityFilterType 
                     : search === "" && status === "" && availability === "" && type !== "" && subType === "" && emphasis === false ? typeFilter 
                     : search === "" && status === "" && availability === "" && type !== "" && subType !== "" && emphasis === false ? subTypeFilter 
                     : search === "" && status === "" && availability === "" && type === "" && subType === "" && emphasis === true ? emphasisFilter 
                     : propertiesSelected

    console.log();


    function handleNewPropertyIntegration(id) {
        console.log(id);
        const property = propertiesSelected.find(property => id === property.id);

        const data = {
            id: property.id,
            idIntegration:property.idIntegration,
            idCompany: "07956b20",
            nameCompany: "Optiva Imobi",
            avatarCompany: "https://upcdn.io/W142hX6/raw/uploads/suachave/AvatarCompany/OPTIVA%20IMOBI/Avatar-15-8-2023%20%7C%2019%3A27/Foto%20de%20perfil%20instagram%20movimento%20fitness%20azul-3aG9.jpg",
            title: property.title, 
            status: property.status, 
            type: property.type,
            subType: property.subType,
            description: property.description,
            priceSale: property.priceSale,
            priceRent: property.priceRent,
            bedroom: property.bedroom,
            garage: property.garage,
            suite: property.suite,
            restroom: property.restroom,
            totalArea: property.totalArea,
         buildingArea: property.buildingArea,
            siglaBuildingArea: property.siglaBuildingArea,
            siglaTotalArea: property.siglaTotalArea,
            road: property.road,
        number: property.number,
            complement: property.complement,
            district: property.district,
            city: property.city,
          uf: property.uf,
            cep: property.cep,
            reference: property.reference,
          latitude: property.latitude,
           longitude: property.longitude,
            images: property.images,
            idLocator: property.idLocator,
            newProperty: property.newProperty,
            firstLease: property.firstLease,
            availability: property.availability,
            furnished: property.furnished,
            pets: property.pets,
            textRent: property.textRent,
            condominium: property.condominium,
            iptu: property.iptu,
            otherPrices: property.otherPrices,
            yearOfConstruction: property.yearOfConstruction,
            featuredImage: property.featuredImage,
            platformVideo: property.platformVideo,
            video: property.video,
            slider: property.slider,
            financing: property.financing,
            emphasis: property.emphasis,
            characteristcs: property.characteristcs,
            dateUpdate: property.dateUpdate,
            codeIptu: property.codeIptu,
            codeEnergy: property.codeEnergy,
            codeWater: property.codeWater,
            informations: property.informations,
            documentsProperty:property.documentsProperty,
            exclusive: property.exclusive,
            permuta: property.permuta,
            laudemio: property.laudemio,
        }

        newProperty(data)
    }
    function handleDeletePropertyIntegration(data) {
        console.log(data);
        const property = propertiesSelected.find(property => data === property.id);
        console.log(property);
    }

    return (
        <div className="MyPropertiesList">
            <NavbarAdm />
            <ToolBar />
            <div className="aside">
                <div className="textHome">
                <h3>Meus imóveis</h3>

                <h4>{data?.length} 
                {plains?.namePlain === "Free" ? "/5 " :
                    plains?.namePlain === "Start" ? "/100 " :
                    plains?.namePlain === "Broker" ? "/100 " :
                    plains?.namePlain === "Lite" ? "/200 " :
                    plains?.namePlain === "Company" ? " " :
                    plains?.namePlain === "Pro" ? " " :
                    "" 
                 }
                  Anúncios
                 </h4>

                <h3><a className="link" href={plains?.namePlain === "Free" && data?.length >= 5 ? "/atualizar-plano/Broker" :
                    plains?.namePlain === "Start" && data?.length >= 100 ? "/atualizar-plano/Company" :
                    plains?.namePlain === "Broker" && data?.length >= 100 ? "/atualizar-plano/Company" :
                    plains?.namePlain === "Lite" && data?.length >= 200 ? "/atualizar-plano/Business" :
                    "/novoimovel"
                 }>+ Novo anúncio</a></h3>
                </div>
              

                <div className="search">
                    <input type="text" placeholder="Busque por: Título, código ou cidade" value={search} onChange={e => setSearch(e.target.value)} onClick={handleClear}/>
                    <div className="selection">
                    <select value={status} onChange={handleStatus}>
                        <option value="">Status</option>
                        <option value="Venda">Venda</option>
                        <option value="Aluguel">Aluguel</option>
                    </select>
                    <select value={availability} onChange={handleAvailability}>
                        <option value="">Visualização</option>
                        <option value="Disponível">Disponível</option>
                        <option value="Alugado">Alugado</option>
                        <option value="Vendido">Vendido</option>
                        <option value="Indisponível">Indisponível</option>
                    </select>
                    <select value={emphasis} onChange={handleEmphasis}>
                        <option value={false}>Sem destaque</option>
                        <option value={true}>Com destaque</option>
                    </select>
                    <select value={type} onChange={handleType}>
                        <option value="">Tipo</option>
                        <option value="Residencial">Residencial</option>
                        <option value="Comercial">Comercial</option>
                        <option value="Industrial">Industrial</option>
                        <option value="Rural">Rural</option>
                        <option value="Terrenos e Lotes">Terrenos e Lotes</option>
                    </select>
                    <select value={subType} onChange={handleSubType}>
                        {type === "Residencial" ?
                        <>
                        <option value="">Subtipo</option>
                        <option value="Casa">Casa</option>
                        <option value="Casa geminada">Casa geminada</option>
                        <option value="Sobrado">Sobrado</option>
                        <option value="Bangalô">Bangalô</option>
                        <option value="Edícula">Edícula</option>
                        <option value="Flat">Flat</option>
                        <option value="Casa de vila">Casa de vila</option>
                        <option value="Condomínio fechado">Condomínio fechado</option>
                        <option value="Apartamento">Apartamento</option>
                        <option value="Apartamento duplex">Apartamento duplex</option>
                        <option value="Cobertura">Cobertura</option>
                        <option value="Cobertura duplex">Cobertura duplex</option>
                        <option value="Loft">Loft</option>
                        <option value="Kitnet">Kitnet</option>
                        <option value="Mansão">Mansão</option>
                        <option value="Stúdio">Stúdio</option>
                        </>
                        : type === "Comercial" ?
                        <>
                        <option value="">Subtipo</option>
                        <option value="Loja">Loja</option>
                        <option value="Conjunto comercial">Conjunto comercial</option>
                        <option value="Ponto comercial">Ponto comercial</option>
                        <option value="Sala Comercial">Sala Comercial</option>
                        <option value="Prédio">Prédio</option>
                        <option value="Hotel">Hotel</option>
                        <option value="Stúdio">Stúdio</option>
                        </>
                        : type === "Industrial" ?
                        <>
                        <option value="">Subtipo</option>
                        <option value="Galpão">Galpão</option>
                        <option value="Área industrial">Área industrial</option>
                        </>
                        : type === "Rural" ?
                        <>
                        <option value="">Subtipo</option>
                        <option value="Chácara">Chácara</option>
                        <option value="Fazenda">Fazenda</option>
                        <option value="Sítio">Sítio</option>
                        </>
                        : type === "Terrenos e Lotes" ?
                        <>
                        <option value="">Subtipo</option>
                        <option value="Área">Área</option>
                        <option value="Terreno/Lote">Terreno/Lote</option>
                        </>
                        :  <option value="">Subtipo</option>
                        }
                    </select>
                    </div>
                </div>
                <div className="search">
                    <button onClick={() => selectLists("")} className={filter !== "" ? "other" : ""}>Imóveis cadastrados <span>{data?.length}</span></button>
                    <button onClick={() => selectLists("New")} className={filter === "New" ? "newSelected" : "new"}>Imóveis da integração <span>{myIntegrationProperty?.length}</span></button>
                    {/* <button onClick={() => selectLists("Old")} className={filter === "Old" ? "deleteSelected" :"delete"}>Imóveis Só aqui <span>{OlsIntegrations?.length}</span></button> */}
                </div>
            <div className="informations">

                {filterData?.map((property) => {
                    return (
                        <div className="propertyListAdm" key={property.id}>
                        <div className="image">
                            <a href="/conversa">
                            <img src={property.featuredImage} alt="" />
                            </a>
                        </div>

                        <div className="textpropertyListAdm">
                            <div className="textDatapropertyListAdm">
                        {/* <h4>{property.title} - {property.id}</h4> */}
                        <h4>ID: {property.id?.toUpperCase()} - ID INTEGRAÇÃO: {property.idIntegration?.toUpperCase()} </h4>
                        <h4>{property.type?.toUpperCase()} - {property.subType?.toUpperCase()} - {property.status?.toUpperCase()}</h4>
                        {/* <h5><IoHomeOutline />{property.type} - {property.subType} - {property.status}</h5> */}
                        <h5><IoLocationOutline />{property.road} - {property.district} - {property.city} -{property.uf} | <DateFormat2 date={property.created_at} /></h5>
                        {/* <h6><DateFormat2 date={property.created_at} /></h6> */}
                            </div>

                            {filter === "New" ?
                        ""
                        : filter === "Old" ?   
                       ""
                    :
                            <>
                            <div className="propertyView">
                            {/* <NewShare idProperty={property.id} title={`${property.subType} - ${property.status} (${property.city} / ${property.uf})`} image={property.featuredImage}/> */}
                            <h4 className="emphasis">Destaque: {property.emphasis === false ?  "Não ": "Sim"}</h4>
                            <h4 className={
                                property.availability === "Disponível" ? "status1" :
                                property.availability === "Vendido" ? "status2" :
                                property.availability === "Alugado" ? "status3" : "status4"
                            }>{property.availability}</h4>
                            </div>
                            <div className="propertyView">
                            {property.exclusive === "Sim" ?  <h4 className="emphasis">Exclusivo</h4> : ""}
                            {property.laudemio === "Sim" ?  <h4 className="emphasis">Laudêmio {property.laudemio}</h4> : ""}
                            {property.permuta === "Sim" ?  <h4 className="emphasis">Permuta: {property.permuta}</h4> : ""}
                            

                            </div>
                            <div className="infosContactData">
                                <div className="infoUnicData">
                                <IoEyeOutline />
                                    <h6> <CountersViews id={property.id}/> Visualizações</h6>
                                </div>
                                <div className="infoUnicData">
                                <IoHeartOutline />
                                    <h6> <CountersFavorites id={property.id}/> Salvos</h6>
                                </div>
                                <div className="infoUnicData">
                                <IoLogoWhatsapp />
                                    <h6> <CountersWhatsapp id={property.id}/> Whatsapp</h6>
                                </div>
                                <div className="infoUnicData">
                                <IoCallOutline />
                                    <h6><CountersContact id={property.id}/> Ligações</h6>
                                </div>

                                <MatchPropertySearch  status={property.status} type={property.type} subType={property.subType}
                                uf={property.uf} city={property.city} district={property.district} 
                                bedroom={property.bedroom} restroom={property.restroom} garage={property.garage}
                                suite={property.suite} pets={property.pets} furnished={property.furnished}/>
                                
                                <MatchProperty id={property.id}/>                        
                            </div>
                            </>
                    }

                        </div>
    
                        {filter === "New" ?
                        <div className="Action">
                            <button className="btnNew" onClick={() => handleNewPropertyIntegration(property.id)}>Adicionar imóvel</button>
                        </div>
                        : filter === "Old" ?   
                        <div className="Action">
                         <button className="btnDelete" onClick={() => handleDeletePropertyIntegration(property.id)}>Remover imóvel</button>
                    </div>
                    :
                    <div className="buttons">

                    <ViewPropertyList id={property.id}/>

                    <a href={`/editarimovel/${property.id}`} className="btnControl" data-tip data-for='Editar'><IoCreateOutline /></a>
                    
                    {plains?.namePlain === "Free" ?
                     <a href={"/atualizar-plano/Start"} className="btnControl" data-tip data-for='Editar'><IoTrashOutline /></a>
                      :
                    <DeleteProperty id={property.id} title={property.title}
                                        address={`${property.road}, ${property.number} - ${property.district} - ${property.city} - ${property.uf}`}
                                        status={`${property.type} - ${property.subType}`}/>
                    }

                    {/* <NewNegotiations idProperty={property.id}/> */}
                    <EditStatusProperty id={property.id}/>
                    </div>
                    }

                    </div>  
                    )
                })}


            </div>
            </div>



        </div>
    )
}