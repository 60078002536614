import { DownloadApp } from "../../components/DownloadApp/DownloadApp"
import NavbarAdm from "../../components/Nav/Navbar"
import { ToolBar } from "../../components/ToolBar/ToolBar"
import "./dashboard.css";
import {IoHomeOutline, IoCalendarOutline, IoLogoWhatsapp, IoCallOutline,IoAlertCircle,
    IoHeartOutline, IoKeyOutline, IoRocketOutline, IoSearchOutline} from 'react-icons/io5'
import { PropertiesCount } from "../../components/ItensDashboard/PropertiesCount";
import { PropertiesCountSale } from "../../components/ItensDashboard/PropertiesCountSale";
import { PropertiesCountRent } from "../../components/ItensDashboard/PropertiesCountRent";
import { EvaluationCount } from "../../components/ItensDashboard/EvaluationCount";
import { SchedulingCount } from "../../components/ItensDashboard/SchedulingCount";
import { ContactWhatsappCount } from "../../components/ItensDashboard/ContactWhatsappCount";
import { ContactPhoneCount } from "../../components/ItensDashboard/ContactPhoneCount";
import { FavoriteCount } from "../../components/ItensDashboard/FavoriteCount";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { PropertiesCountAvailability } from "../../components/ItensDashboard/PropertiesCountAvailability";
import { ContactLeadCount } from "../../components/ItensDashboard/ContactLeadCount";
import { SchedulingAllCompleted } from "../../components/ItensDashboard/SchedulingAllCompleted";
import { SchedulingAll } from "../../components/ItensDashboard/SchedulingAll";
import { PropertiesCountRentSale } from "../../components/ItensDashboard/PropertiesCountRentSale";
import { ProposalsCounter } from "../../components/ItensDashboard/ProposalsCounter";
import { Commissions } from "../../components/ItensDashboard/Commissions";
import { SalesProperties } from "../../components/ItensDashboard/SalesProperties";
import { RentProperties } from "../../components/ItensDashboard/RentProperties";
import { TotalRevenue } from "../../components/ItensDashboard/TotalRevenue";


export function Dashboard() {

    const Local = localStorage.getItem("adm-suachave");
    const user = JSON.parse(Local);

    const [myPayments, setMyPayments] = useState([])
    const [myPlain, setMyPlain] = useState([])
    const [daysPayments, setDaysPayments] = useState()
    const [daysCreateAccount, setDaysCreateAccount] = useState()
    const [textAlert, setTextAlert] = useState("")

    useEffect(() => {
        async function verifyPaymentStatus() {
            //Dados de plano
            const plains =  await api.get(`/myplain/${user.id}`);
            setMyPlain(plains.data)

            //Dados de pagamentos
            const payment =  await api.get(`/payments/${user.id}`)
            setMyPayments(payment.data)
            
            if(plains.data[0]?.namePlain === "Free") {
                return
            }

            const dateCreation1 = new Date(user.date);
            const dateCriation2 = new Date();
            const timeDiffCreate = Math.abs(dateCriation2.getTime() - dateCreation1.getTime());
            const diffDaysCreate = Math.ceil(timeDiffCreate / (1000 * 3600 * 24)); 
            console.log("Dias de criação da conta")
            console.log(diffDaysCreate);
            setDaysCreateAccount(diffDaysCreate)
            console.log(plains.data.length)
            
            if(plains.length === 0 && diffDaysCreate > 8 ) {
                window.open("/escolher-plano", "_self");
                return;
            }
            


            const date1 = new Date(payment.data[0]?.created_at);
            const date2 = new Date();
            const timeDiffPayments = Math.abs(date2.getTime() - date1.getTime());
            const diffDaysPayments = Math.ceil(timeDiffPayments / (1000 * 3600 * 24)); 
            setDaysPayments(diffDaysPayments);
           
            if(payment.data[0]?.status === "Pendente" && diffDaysPayments > 5 ) {
                window.open("/pagamento-pendente", "_self");
                return;
            }

            console.log("dataInicial")
            const dataInicial = new Date(payment.data[0]?.created_at)
            dataInicial.setDate(dataInicial.getDate() - 6)
            dataInicial.setMonth(dataInicial.getMonth() + 1)
            console.log(dataInicial.toString())
            console.log("--------------------------")


            console.log("DataBase")
            const dataBase = new Date(payment.data[0]?.created_at)
            dataBase.setMonth(dataBase.getMonth() + 1)
            console.log(dataBase.toString())
            console.log("--------------------------")

            console.log("dataFinal")
            const dataFinal = new Date(payment.data[0]?.created_at)
            dataFinal.setDate(dataFinal.getDate() + 6)
            dataFinal.setMonth(dataFinal.getMonth() + 1)
            console.log(dataFinal.toString())
            console.log("--------------------------")




            console.log("Hoje")
            const hoje = new Date()
            hoje.setDate(hoje.getDate() + 0)
            hoje.setMonth(hoje.getMonth() + 0)
            console.log(hoje.toString())
            console.log("--------------------------")

            if(new Date() > new Date(dataFinal) ) {
               window.open("/pagamento-pendente", "_self")
            }


            if(diffDaysCreate < 8 ) {
                setTextAlert(`Você está no seu ${diffDaysCreate}º dia de período gratuito. Aproveite nossa plataforma.`)
                console.log(`Você está no seu ${diffDaysCreate}º dia de período gratuito. Aproveite nossa plataforma.`)
                return
            }

            if(new Date() > new Date(dataInicial) && new Date() <= new Date(dataFinal) && new Date(payment.data[0]?.created_at) < new Date(dataInicial)) {
                console.log(`Pagamento vence dia ${new Date(payment.data[0]?.created_at).getDate()}. Não se preocupe, você pode pagar até 5 dias após o vencimento do plano.`);
                setTextAlert(`Pagamento vence dia ${new Date(payment.data[0]?.created_at).getDate()}. Não se preocupe, você pode pagar até 5 dias após o vencimento do plano.`)
                return
            }
            
            if(new Date() === new Date(dataBase) && new Date(payment.data[0]?.created_at) < new Date(dataInicial)) {
                console.log(`Pagamento vence hoje`);
                setTextAlert(`Seu plano vence hoje. Não se preocupe, você pode pagar até 5 dias após o vencimento do plano.`)
                return
            }
            
            if(new Date() >= new Date(dataInicial) && new Date(payment.data[0]?.created_at) < new Date(dataInicial)) {
                console.log(`Seu plano venceu no último dia ${new Date(payment.data[0]?.created_at).getDate()}`)
                setTextAlert(`Seu plano venceu no último dia ${new Date(payment.data[0]?.created_at).getDate()}. Não se preocupe, você pode pagar até 5 dias após o vencimento do plano.`)
                return
            }
            

            if(payment.data[0]?.status === "Pendente") {
                console.log(`Gerado + Pendente`);
                setTextAlert(`Seu bolix foi gerado, porém ainda está pendente. Não se preocupe, seu boleto vence 5 dias após ser gerado.`)
                return
            }




      
      
        }




        verifyPaymentStatus()
    },[])

    return (
        <div className="Dashboard">
            <NavbarAdm />
            <ToolBar />
            <div className="aside">
                <div className="textHome">
                <h3>Olá, {user.fantasyName}.</h3>
                <h5>Última atualização: 02/05/2023. <a href="/atualizacoes">Veja as atualizações</a></h5>
                </div>

            {
            myPlain === "Free" ?
                ""
            : daysCreateAccount < 8 ?
            <div className="PlainDashboard2">
                <div className="text">
                <h4>{textAlert}</h4>
                </div>
                {myPayments?.length > 0 ?
                <a href="/meus-planos">Assinar um plano agora.</a>
                :
                ""}
            </div>
            : textAlert !== "" && textAlert.includes("Seu bolix foi gerado") ?
            <div className="PlainDashboard2">
                <div className="text">
                <h4>{textAlert} </h4>
                </div>
                <a href="/meus-planos">Ver meus boletos</a>
            </div>
            : textAlert !== "" ?
            <div className="PlainDashboard">
                <div className="text">
                <h4>{textAlert} </h4>
                <h5>Caso ja tenha pago. Desconsidere a mensagem</h5>
                </div>
                <a href="/meus-planos">Efetuar pagamento</a>
            </div>
            : ""
            }


                <div className="topInfomations">
                    <div className="properties">
                       <div className="infosProperty">
                        <div className="totalProperties">
                            <h5>Imóveis publicados</h5>
                            <h2><PropertiesCount /></h2>
                        </div>
                        <div className="qtdProperties">
                            <h5>Mais informações</h5>
                            <div className="rent">
                                <h6><PropertiesCountRent /> Imóveis para aluguel</h6>
                            </div>
                            <div className="sale">
                                <h6><PropertiesCountSale /> Imóveis à venda</h6>
                            </div>
                        </div>
                       </div>
                       <div className="availability">
                        <h6>Disponíveis: <PropertiesCountAvailability availability={"Disponível"} /></h6>
                        <h6>Alugados: <PropertiesCountAvailability availability={"Alugado"} /></h6>
                        <h6>Vendidos: <PropertiesCountAvailability availability={"Vendido"} /></h6>
                        <h6>Indisponíveis: <PropertiesCountAvailability availability={"Indisponível"} /></h6>
                       </div>
                       <div className="buttonsProperty">
                        <a href="/imoveis">Ver Imóveis</a>
                        <a href="/novoimovel">Novo Imóvel</a>
                       </div>
                    </div>

                    <div className="funel">
                        <div className="infosFunel">
                        <h5>Funil de vendas</h5>
                            <div className="typefunel">
                                    <div className="funelUnic">
                                        <div className="lead">
                                        </div>
                                        <div className="lead-text">
                                            <h5>Leads</h5>
                                        <h5><ContactLeadCount /></h5>
                                        </div>
                                    </div>
                                    <div className="funelUnic">
                                        <div className="sheduling">
                                        </div>
                                        <div className="sheduling-text">
                                        <h5>Agendamentos</h5>
                                        <h5><SchedulingAll /></h5>
                                        </div>
                                    </div>
                                    <div className="funelUnic">
                                    <div className="completed">
                                        </div>
                                        <div className="completed-text">
                                    <h5>Visitas</h5>
                                        <h5><SchedulingAllCompleted /></h5>
                                        </div>
                                    </div>
                                    <div className="funelUnic">
                                    <div className="proposals">
                                        </div>
                                        <div className="proposals-text">
                                    <h5>Propostas</h5>
                                        <h5><ProposalsCounter /></h5>
                                        </div>
                                    </div>
                                    <div className="funelUnic">
                                    <div className="status">
                                        </div>
                                        <div className="status-text">
                                    <h5>Fechamentos</h5>
                                        <h5><PropertiesCountRentSale /></h5>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="invoicing">
                        <div className="infosInvoicing">
                        <h5>Faturamento</h5>
                        <div className="valuesInvoicing">
                            <div className="sales">
                                <h5>Vendas</h5>
                                <h4>R$ 0,00</h4>
                                {/* <h4><SalesProperties /></h4> */}
                            </div>
                            <div className="rents">
                                <h5>Locações</h5>
                                <h4>R$ 0,00</h4>
                                {/* <h4><RentProperties /></h4> */}
                            </div>
                            <div className="total">
                                <h5>Faturamento total</h5>
                                <h4>R$ 0,00</h4>
                                {/* <h4><TotalRevenue /></h4> */}
                            </div>
                            <div className="futures">
                                <h5>Previsão</h5>
                                <h4>R$ 0,00</h4>
                            </div>
                            <div className="commissions">
                                <h5>Comissões</h5>
                                <h4>R$ 0,00</h4>
                                {/* <h4><Commissions /></h4> */}
                            </div>
                            <div className="business">
                                <h5>Negócios ativos</h5>
                                <h4>R$ 0,00</h4>
                                {/* <h4><ProposalsCounter /></h4> */}
                            </div>
                        </div>

                        </div>
                    </div>
                </div>

            {/* <div className="informations">

            <div className="dataSheduling">
                <div className="shedulingInfo">
                        <IoCalendarOutline />
                        <h3><SchedulingCount /></h3>
                    </div>
                    <h5>Agendamentos de hoje</h5>
                </div>
 
                <div className="infoData">
                <div className="topInfo">
                        <IoSearchOutline />
                        <h3><EvaluationCount /></h3>
                    </div>
                    <h5>Imóveis para avaliar</h5>
                </div>

                <div className="infoData">
                <div className="topInfo">
                        <IoLogoWhatsapp />
                        <h3><ContactWhatsappCount /></h3>
                    </div>
                    <h5>Contatos de Whatsapp</h5>
                </div>
                <div className="infoData">
                <div className="topInfo">
                        <IoCallOutline />
                        <h3><ContactPhoneCount /></h3>
                    </div>
                    <h5>Contatos via ligação</h5>
                </div>
                <div className="infoData">
                <div className="topInfo">
                        <IoHeartOutline />
                        <h3><FavoriteCount /></h3>
                    </div>
                    <h5>Salvos no favoritos</h5>
                </div>

            </div> */}
            {/* <div className="PlainDashboard2">
                <h4><IoAlertCircle /> Serviço de upload de imagens reestabelecido.</h4>
                <a href="/planos">Renovar agora</a>
            </div> */}


            <DownloadApp />
            </div>
        </div>
    )
}