import "./viewPropertyList.css"
import { useEffect, useState } from "react";
import { IoBedOutline, IoCarSportOutline, IoCrop, IoDocumentAttachOutline, IoEyeOutline, IoHome, IoHomeOutline, IoLocationOutline } from "react-icons/io5";
import { MdOutlineShower } from "react-icons/md";
import { TbBath, TbBone, TbSofa } from "react-icons/tb";
import { useFetch } from "../../hooks/useFetch";
import ReactTooltip from 'react-tooltip';
import { NewShare } from "../NewShare/NewShare";
import { GiHomeGarage } from "react-icons/gi";
import {TfiRulerAlt2} from 'react-icons/tfi';
import api from "../../services/api";

export function ViewPropertyList({id}) {

    const [filter, setFilter] = useState(false);
    const [data, setData] = useState([]);

          function handleFiltro(e) {
            e.preventDefault();
    
            setFilter(!filter)
        }

        useEffect(() => {
            async function loadInfosProperty() {
                await api.get(`/property/${id}`).then((res) => {
                    setData(res.data[0]);
                }).catch((err) => {
                    console.error(err)
                });
            }

            loadInfosProperty()
        },[])




const valuesRent =[
    // {
    // id: "rent",
    // value: data?.priceRent === "" ? parseFloat(data?.priceSale) * plusSale : parseFloat(data?.priceRent) * plusRent
    // },
    {
    id: "condominium",
    value: data?.condominium === "" ? " 0" : data?.condominium
    },
    {
    id: "iptu",
    value: data?.iptu === "" ? " 0" : data?.iptu
    },
    {
    id: "otherPrices",
    value: data?.otherPrices === "" ? " 0" : data?.otherPrices
    }
]

const payments = valuesRent?.reduce(function (acumulador, objetoAtual){
    return acumulador + parseFloat(objetoAtual.value);
  }, 0);

  var ResultBRL = payments.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})


    return (
        <div className="ViewPropertyList">
            <button className="btnControl" onClick={handleFiltro} data-tip data-for='Ver Imóvel'><IoEyeOutline /></button>

        <div className={filter === true ? "searchItensFilter" : "searchItensFilterNone"}>
        <div className="buttonsFilter">
        <button className="btnFilter" onClick={handleFiltro}>X</button>
        </div>
        <div className="searchOptionsFilter">
        <div className="form">
                <div className="imageClient">
                    <img src={data?.featuredImage} alt="Imagem avatar do cliente" />
                </div>
                  <h3>{data?.title}</h3>
                  <h5>ID: {id}</h5>
                  <h5><IoHomeOutline/>{data?.status} - {data?.type} - {data?.subType}</h5>
                  <h6><IoLocationOutline />{data?.city} - {data?.uf}</h6>

                  <NewShare idProperty={data?.id} title={`${data?.subType} - ${data?.status} (${data?.city} / ${data?.uf})`} image={data?.featuredImage}/>


                  <div className="iconsBox">
                    {data?.bedroom === "0" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <IoBedOutline />
                            <p>Quartos</p>
                            </div>
                            <p>{data?.bedroom}</p>
                        </div>
                    }
                        {data?.suite === "0" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <TbBath />
                            <p>Suítes</p>
                            </div>
                            <p>{data?.suite}</p>
                        </div>
                        }
                        {data?.restroom === "0" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <MdOutlineShower />
                            <p>Banheiro</p>
                            </div>
                            <p>{data?.restroom}</p>
                        </div>
                        }
                        {data?.garage === "0" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <GiHomeGarage />
                                <p>Vagas</p>
                            </div>
                                <p>{data?.garage}</p>
                        </div>
                        }
                          {data?.pets === "Não" || data?.pets === "" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <TbBone />
                            <p>Pets</p>
                            </div>
                            <p>{data?.pets}</p>
                        </div>
                        }
                         {data?.furnished === "Não" || data?.furnished === ""? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <TbSofa />
                                <p>Mobilhado</p>
                            </div>
                                <p>{data?.furnished}</p>
                        </div>
                        }
                        {data?.totalArea === "" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <TfiRulerAlt2 />
                                <p>Total</p>
                            </div>
                                <p>{data?.totalArea} M<sup>2</sup></p>
                        </div>
                            }
                        {data?.buildingArea === "" ? "" :
                        <div className="iconUnicBox">
                            <div className="simbolBox">
                                <IoCrop />
                                <p>Constr.</p>
                            </div>
                                <p>{data?.buildingArea} M<sup>2</sup></p>
                        </div>
                        }
                    </div>


                    <div className="infosCompany">
                {data?.status === "Aluguel" ?
                    <>
                    {data?.priceRent === "" ? 
                     <div className="pricing">
                     <h5>Aluguel / <span>Mensal</span></h5>
                     <h2><span>Preço não definido</span></h2>
                 </div>
                  :
                    <div className="pricing">
                        <h5>Aluguel / <span> {data?.textRent}</span></h5>
                        <h2>R$ <span>{data?.priceRent}</span></h2>
                    </div>
                    }
                    {data?.condominium === "" || data?.condominium === "0,00" ? ""  :
                    <div className="otherPrincings">
                        <h5>Condomínio</h5>
                        <h5>R$ {data?.condominium}</h5>
                    </div>
                        }
                     {data?.iptu === "" || data?.iptu === "0,00" ? "" :
                    <div className="otherPrincings">
                        <h5>IPTU</h5>
                        <h5>R$ {data?.iptu}</h5>
                    </div>
                     }
                     {data?.otherPrices === "" || data?.otherPrices === "0,00" ? ""  :
                    <div className="otherPrincings">
                        <h5>Outros encargos</h5>
                        <h5>R$ {data?.otherPrices}</h5>
                    </div>
                     }
                      {ResultBRL === "" ? "" :
                    <div className="pricingTotal">
                        <h4>Pacote Locação</h4>
                         <h4>{ResultBRL}</h4>
                     </div>
                    }
                     {data?.priceSale === "" ? 
                        ""
                     :
                     <div className="pricing">
                         <h5>Venda</h5>
                         <h2>R$ <span>{data?.priceSale}</span></h2>
                     </div>
                     }
                  
                    </>
                    : data?.status === "Venda" ?
                    <>
                       {data?.priceSale === "" ? 
                        <div className="pricing">
                            <h5>Venda</h5>
                            <h2><span>Preço não definido</span></h2>
                        </div>
                     :
                     <div className="pricing">
                         <h5>Venda</h5>
                         <h2>R$ <span>{data?.priceSale}</span></h2>
                     </div>
                     }
                  
                   {data?.condominium === "" || data?.condominium === "0,00" ? ""  :
                   <div className="otherPrincings">
                       <h5>Condomínio</h5>
                       <h5>R$ {data?.condominium}</h5>
                   </div>
                       }
                    {data?.iptu === "" || data?.iptu === "0,00" ? ""  :
                   <div className="otherPrincings">
                       <h5>IPTU</h5>
                       <h5>R$ {data?.iptu}</h5>
                   </div>
                    }
                    {data?.otherPrices === "" || data?.otherPrices === "0,00" ? ""  :
                   <div className="otherPrincings">
                       <h5>Outros encargos</h5>
                       <h5>R$ {data?.otherPrices}</h5>
                   </div>
                    }
                     {ResultBRL === "" ? "" :
                   <div className="pricingTotal">
                       <h4>Pacote locação</h4>
                        <h4>{ResultBRL}</h4>
                    </div>
                   }
                  
                   </>
                   : data?.status === "Aluguel e Venda" ?
                   <>
                     {data?.priceRent === "" ? 
                     <div className="pricing">
                     <h5>Aluguel / <span>Mensal</span></h5>
                     <h2><span>Preço não definido</span></h2>
                 </div>
                  :
                    <div className="pricing">
                        <h5>Aluguel / <span> {data?.textRent}</span></h5>
                        <h2>R$ <span>{data?.priceRent}</span></h2>
                    </div>
                    }
                    {data?.condominium === "" || data?.condominium === "0,00" ? ""  :
                    <div className="otherPrincings">
                        <h5>Condomínio</h5>
                        <h5>R$ {data?.condominium}</h5>
                    </div>
                        }
                     {data?.iptu === "" || data?.iptu === "0,00" ? "" :
                    <div className="otherPrincings">
                        <h5>IPTU</h5>
                        <h5>R$ {data?.iptu}</h5>
                    </div>
                     }
                     {data?.otherPrices === "" || data?.otherPrices === "0,00" ? ""  :
                    <div className="otherPrincings">
                        <h5>Outros encargos</h5>
                        <h5>R$ {data?.otherPrices}</h5>
                    </div>
                     }
                      {ResultBRL === "" ? "" :
                    <div className="pricingTotal">
                        <h4>Pacote locação</h4>
                         <h4>{ResultBRL}</h4>
                     </div>
                    }
                     {data?.priceSale === "" ? 
                        <div className="pricing">
                            <h5>Venda</h5>
                            <h2><span>Preço não definido</span></h2>
                        </div>
                     :
                     <div className="pricing">
                         <h5>Venda</h5>
                         <h2>R$ <span>{data?.priceSale}</span></h2>
                     </div>
                     }
                  
              
                 
                  </>
                  : ""
                   
                    }
                </div>
                <div className="documents">
                        <h5>Documentos anexados:</h5>
                        {data?.documentsProperty?.map((doc) => {
                            return (
                                <div className="documentUnic">
                                     <a href={doc?.link} target="_blank"><IoDocumentAttachOutline />{doc?.name}</a> 
                                </div>
                            )
                        })}
                        </div>
                <div className="information">
                <h5>Informações:</h5>
                <h6>{data?.informations}</h6>
            </div>
                
            </div>
        </div>
    </div>
        </div>
    )
}