import "./partners.css"
import NavbarAdm from "../../components/Nav/Navbar";
import { ToolBar } from "../../components/ToolBar/ToolBar";
import { MiniMenu } from "../../components/MiniMenu/MiniMenu";
import { useEffect, useState } from "react";
import api from "../../services/api";
import Modal from 'react-modal';
import { useContext } from "react";
import { AuthContext } from "../../contexts/Auth";
import { IoTrashOutline } from "react-icons/io5";

export function Partners() {
    const Local = localStorage.getItem("adm-suachave");
    const user = JSON.parse(Local);
    
    const {integrationCLienteNew} = useContext(AuthContext)

    const [plain, setPlain] = useState();
    const [myPayment, setMyPayment] = useState();
    const [integrations, setIntegrations] = useState("");
    const [type, setType] = useState("");
    const [business, setBusiness] = useState("");
    const [link, setLink] = useState("");

  useEffect(() => {
      async function loadMyPlain() {
        await api.get(`/myplain/${user.id}`).then((res) => {
          loadPlains(res.data[0]?.idPlain)
          setMyPayment(res.data[0])
        })
      }
      loadMyPlain()

      async function loadPlains(id) {
        await api.get(`/plains/plain/${id}`).then((res) => {
          setPlain(res.data[0])
        })
      }
      loadPlains()
  
      async function loadIntegrations(id) {
        await api.get(`/integrationsCompany/company/${user.id}`).then((res) => {
          setIntegrations(res.data[0])
          console.log(res.data[0])
        })
      }
      loadIntegrations()
    }, []);

    const [isOpenModalLocator, setIsOpenModaLocator] = useState(false);

    function handleOpenModalLocator(e) {
      e.preventDefault();
        setIsOpenModaLocator(true)
      }
    
      function handleCloseModalLocator(e) {
        e.preventDefault();
        setIsOpenModaLocator(false);
      }


      function handleSelectType(e) {
        setType(e.target.value);
        console.log(e.target.value)
    }

    

    function newIntegration(e) {
      e.preventDefault();
        integrationCLienteNew({
          idCompany: user.id,
          nameCompany: user.fantasyName,
          avatarCompany: user.logo,
          business,
          link,
          type,
          email: user.email,
          phone: user.phone,
          whatsapp: user.whatsapp,
        });
    }

    Modal.setAppElement('#root');

    return (
        <div className="Partners">
             <NavbarAdm />
            <ToolBar />
            <div className="aside">
            <MiniMenu />
                <div className="textHome">
                    <h3>Integrações e Parcerias</h3>
                </div>
                
            <div className="informations">
                <div className="infoData">
                  <div className="TextInfoData">
                    <h4>Integração com portais (Leads4Sales): </h4>
                    <h5> Link: https://sua-chave-api.herokuapp.com/property/integration/{user.id}</h5>
                </div>
                </div>
                {/* <dv className="infoData">
                    <h4>Tour Virtual - Crie seu tour: </h4>
                    <h5> Acessar: <a href={myPayment?.namePlain === undefined || myPayment?.namePlain === "Basic" || myPayment?.namePlain === "Start" || myPayment?.namePlain === "Lite" ?
                            "/atualizar-plano/Tour"
                            : "#"}>ir para plataforma</a></h5>
                </dv> */}
            </div>

            <div className="textHome">
                    <h3>Integração de importação</h3>
                </div>
            <div className="informations">
                <dv className="infoData">
                  <div className="TextInfoData">
                  {integrations === "" ?
                 ""
                  :
                  <>
                    <h4>Minha integração </h4>
                    {integrations !== null || integrations !== undefined || integrations !== "" ?
                  <>
                  <h5>Empresa: <span>{integrations?.business}</span> </h5>
                  <h5>Link: <span>{integrations?.link}</span> </h5>
                </>
                  :
                  <h5>Clique ao lado e cadastre seu link de integração </h5>
                  }

                  </>
                  }
                   
                  </div>
                  
                  {integrations !== null || integrations !== undefined || integrations !== "" ?
                  <button onClick={"handleOpenModalLocator"}><IoTrashOutline /> Deletar</button>
                  :
                  <button onClick={handleOpenModalLocator}>+ Nova integração</button>
                  }
                </dv>
                
            </div>
            </div>



            <Modal isOpen={isOpenModalLocator} onRequestClose={handleCloseModalLocator}
        overlayClassName="react-modal-overlay"
        className="react-modal-content">
 
        <div className="content-moda-Locator">
        <div className="itensModal-Locator">
            {/* <h3>Novo colaborador</h3> */}
            <h3>Nova integração</h3>

            <div className="form">
                <div className="DataInputs">
                    <div className="dataInputUnic">
                    <h5>Empresa</h5>
                    <input type="text" value={business} onChange={e => setBusiness(e.target.value)}/>
                    </div>
                    <div className="dataInputUnic">
                    <h5>Link</h5>
                    <input type="text" value={link} onChange={e => setLink(e.target.value)}/>
                    </div>
                    <div className="dataInputUnic">
                    <h5>Tipo de integração</h5>
                       <select value={type} onChange={handleSelectType}>
                        <option value=""></option>
                        <option value="XML">XML</option>
                        <option value="JSON">JSON</option>
                       </select>
                    </div>
                </div>


                <div className="ButtonsForm">
                <button className="send" onClick={newIntegration}>Cadastrar Integração</button>
                <button className="cancel" onClick={handleCloseModalLocator}>X Cancelar</button>
                </div>
            </div>
        </div>
        </div>
        </Modal>
        </div>
    )
}