import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {IoSpeedometerOutline, IoPersonOutline, IoHeart, IoNotificationsOutline, IoLogOutOutline, IoChatboxEllipsesOutline,
        IoHomeOutline, IoLaptopOutline, IoCalendarOutline, IoOptionsOutline, IoFunnelOutline, IoSearchOutline, IoQrCodeOutline } from 'react-icons/io5';
import { AuthContext } from '../../contexts/Auth';
import { ChatCounterMenu } from '../ButtonsCounter/ChatCounterMenu/ChatCounterMenu';
import { NotificationCounterMenu } from '../ButtonsCounter/NotificationCounterMenu/NotificationCounterMenu';
import api from '../../services/api';
import { ImConnection } from 'react-icons/im';
import { TbCurrencyDollar } from 'react-icons/tb';
import { BsBuilding } from 'react-icons/bs';
import { HiOutlineClipboardDocumentCheck } from 'react-icons/hi2';
import { MdOutlineChecklistRtl } from 'react-icons/md';
import { RiExchangeDollarLine } from 'react-icons/ri';

const UlAdm = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
 

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:5px;
    font-weight: 700;
  }
  .nav-item2 {
    display: none;
  }
  li a{
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: 700;
    color: var(--Paragraph);
    padding: 5px;
    border-radius: 6px;
    margin-left: -15px;
  }
  li a svg {

    margin-bottom: 2px;
    font-size: 16px;
  }
  li a p {
    font-size: 12px;
    font-weight: 700;
  }
  li a:hover{
    padding: 5px;
    color: var(--Primary);
  }
  button {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 5px;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    margin: 5px 0;
    color: var(--White);
    border: none;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
    font-weight: 500;
  }

  button svg {
    margin: 0px;
  }

  button:hover{
    background-color: var(--ButtonHover);
    color: var(--White);
  }
  .btn {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    background-color: var(--Yellow);
    border-radius: 6px;
    margin: 5px 0;
    color: var(--White);
    border: none;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;

  }

  btn:hover{
    background-color: var(--Blue);
  }


  @media (max-width: 900px) {
    flex-flow: column nowrap;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    background-color: rgba(255,255,255);
    z-index: 98;

    li a{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-weight: 500;
      color: var(--Paragraph);
      padding: 10px 20px;
      border-radius: 20px;
    }
    li a svg {
      margin-right: 10px;
    }
    li a:hover {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-weight: 500;
      color: var(--White);
      padding: 10px 20px;
      background-color: var(--Primary);
      margin: 2px auto;
      border-radius: 20px;
    }


    button{
      display: block;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: var(--Black);
      color: var(--White);
      margin-left: 10px;
      margin-right: 10px;
      padding: 10px 0;
    }
    button svg {
      margin-right: 0px;
    }

    button:hover{
      background-color: var(--ButtonHover);
    }
    .btn{
      display: block;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color:  var(--Secondary);
      margin-left: 10px;
      margin-right: 10px;
      padding: 10px 0;
    }
    .btn svg {
      margin-right: 0px;
    }

    .btn:hover{
      background-color: var(--Blue);
    }

    @media (max-width: 900px) {
      .nav-item2 {
        display: block;
        margin: 2px auto;
        width: 90%;
        border-radius: 20px;
        background-color: var(--Border);
      }

      .nav-item2:hover {
        background: none;
      } 
      .nav-item {
        display: none;
      }
      .account button {
        display: block;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: var(--Primary);
        color: var(--White);
        margin-left: 10px;
        margin-right: 10px;
        padding: 0px;
        font-size: 16px;
        border-radius: 20px;
      }
      .account button svg {
        margin: 0px;
      }
    }
  }

  }
`;


const RightNav2 = ({ open }) => {

  const {logout} = useContext(AuthContext);

function HandleLogout(e) {
  e.preventDefault();
  logout()
}

const Local = localStorage.getItem("adm-suachave");
const user = JSON.parse(Local);

const [plain, setPlain] = useState([]);
const [myPlain, setMyPlain] = useState([]);

const dateCreation1 = new Date(user.date);
const dateCriation2 = new Date();
const timeDiffCreate = Math.abs(dateCriation2.getTime() - dateCreation1.getTime());
const diffDaysCreate = Math.ceil(timeDiffCreate / (1000 * 3600 * 24)); 


  useEffect(() => {
      async function loadMyPlain() {
        await api.get(`/myplain/${user.id}`).then((res) => {
          loadPlains(res.data[0]?.idPlain)
          setMyPlain(res.data[0])
        })
      }

      async function loadPlains(id) {
        await api.get(`/plains/plain/${id}`).then((res) => {
          setPlain(res.data[0])
        })
      }
      loadMyPlain()
    }, []);


  return (
    <UlAdm open={open}>

       <li className='nav-item'>
            <Link to='/home'>
           <IoSpeedometerOutline /> <p>Painel</p> 
            </Link>
          </li> 
           <li className='nav-item'>
            <Link to='/imoveis' >
           <IoHomeOutline /> <p>Imóveis</p>
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/leads' >
           <IoFunnelOutline /> <p>Leads</p>
           <ChatCounterMenu />
            </Link>
          </li>
                            <li className='nav-item'>
                              <Link to='/agendamentos' >
                             <IoCalendarOutline /> <p>Agenda</p> 
                              </Link>
                            </li>
          <li className='nav-item2'>
            <Link to={ myPlain?.length === 0 || diffDaysCreate < 8 ?
                    "/avaliacoes"
                    :myPlain?.namePlain === undefined || myPlain?.namePlain === "Free" ?
                            "/atualizar-plano/Broker"
                            : "/avaliacoes"} >
           <IoSearchOutline /> <p>Avaliações</p>
           <ChatCounterMenu />
            </Link>
          </li>
          {/* <li className='nav-item'>
            <Link to='/mensagens' >
           <IoChatboxEllipsesOutline /> <p>Chat</p> 
            </Link>
          </li> */}

          <li className='nav-item2'>
            <Link to='/match' >
            <ImConnection /> <p>Match</p> 
            </Link>
          </li> 
          <li className='nav-item2'>
            <Link to={ myPlain?.length === 0 || diffDaysCreate < 8 ?
                    "/propostas"
                    :myPlain?.namePlain === undefined || myPlain?.namePlain === "Free" ?
                            "/atualizar-plano/Broker"
                            : "/propostas" } >
            <HiOutlineClipboardDocumentCheck /> <p>Propostas</p> 
            </Link>
          </li> 
          <li className='nav-item2'>
            <Link to={ myPlain?.length === 0 || diffDaysCreate < 8 ?
                    "/vendas"
                    :myPlain?.namePlain === undefined || myPlain?.namePlain === "Free" ?
                            "/atualizar-plano/Broker"
                            : "/vendas" } >
            <BsBuilding /> <p>Vendas</p> 
            </Link>
          </li> 
          <li className='nav-item2'>
            <Link to='/vistorias' >
            <MdOutlineChecklistRtl /> <p>Vistorias</p> 
            </Link>
          </li> 
          <li className='nav-item2'>
            <Link to={ myPlain?.length === 0 || diffDaysCreate < 8 ?
                    "/encargos"
                    :myPlain?.namePlain === undefined || myPlain?.namePlain === "Free"?
                            "/atualizar-plano/Broker"
                            : "/encargos"} >
            <RiExchangeDollarLine /> <p>Encargos</p> 
            </Link>
          </li> 
          <li className='nav-item2'>
            <Link to={ myPlain?.length === 0 || diffDaysCreate < 8 ?
                    "/financeiro"
                    :myPlain?.namePlain === undefined || myPlain?.namePlain === "Free" ?
                            "/atualizar-plano/Broker"
                            : "/financeiro"} >
            <TbCurrencyDollar /> <p>Financeiro</p> 
            </Link>
          </li> 
          <li className='nav-item2'>
            <Link to='/meus-planos' >
           <IoQrCodeOutline /> <p>Meu plano</p> 
            </Link>
          </li> 
          <li className='nav-item2'>
            <Link to='/meu-site' >
           <IoLaptopOutline /> <p>Meu site</p> 
            </Link>
          </li> 


          {/* <li className='nav-item2'>
            <Link to='/menu' >
           <IoOptionsOutline /> <p>Mais opções</p> 
            </Link>
          </li>  */}
          <div className="account">
           <button className="account" onClick={HandleLogout}><IoLogOutOutline /></button>
          </div>       
    </UlAdm>
  )
}

export default RightNav2
