import "./newShare.css"
import { IoShareOutline, } from "react-icons/io5";
import { RWebShare } from "react-web-share";
import ReactTooltip from 'react-tooltip';
import { FiShare2 } from "react-icons/fi";


export function NewShare({idProperty, title, image}) {
  // console.log({idProperty, title, image})
  // console.log(image)

  function handleShareNew() {
    const data = {
      // files: [
      //   new File([image], "Imagem.jpg", {
      //     type: image.type,
      //   }),
      // ],
      title: title,
      text: title,
      url: `https://www.suachave.com.br/imovel/${idProperty}`
    }

    if (!navigator.canShare(data)) {
      console.error("Can't share");
    }
    navigator.share(data);
  }
    return (
        <>
      <RWebShare
        data={{
          text: `${title}`,
          img: `${image}`,
          url: `https://www.suachave.com.br/imovel/${idProperty}`,
          title: `${title}`,
        }}
        onClick={() => console.log("shared successfully!")}
      >
        <>
        <button className="Compartilhar" data-tip data-for='Compartilhar' onClick={handleShareNew}><FiShare2 /> Compartilhar</button>
        {/* <button className="Compartilhar" data-tip data-for='Compartilhar' onClick={handleShareNew}><FiShare2 /> Teste 2</button> */}
        </>
        {/* <ReactTooltip id='Compartilhar' place="bottom" type="dark" effect="solid">
                         <span>Compartilhar</span>
                        </ReactTooltip> */}
      </RWebShare>

        </>
    )
}
